import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '../public/css/reset.css';
import '../public/fonts/iconfont.css';
import './utils/testingEquipment'
import 'element-ui/lib/theme-chalk/base.css';
import 'element-ui/lib/theme-chalk/index.css';
import {Carousel,CarouselItem} from 'element-ui';
Vue.component(Carousel.name, Carousel)
Vue.component(CarouselItem.name, CarouselItem)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
